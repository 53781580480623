// 此组件实现图片、PDF文件弹出展示
<template>
  <a-modal
    :title="`查看${currentItemType === 'img' ? '图片' : '文件'} ${indexText}`"
    :width="'50vw'"
    :visible="true"
    :footer="null"
    centered
    @cancel="closeAction"
  >
    <div class="wrapper">
      <a-button @click="gotoPrevious" :disabled="currentItemIndex <= 0" shape="circle" icon="left" />
      <div v-if="currentItemType === 'img'" class="image-wrapper">
        <img style="width: 100%;" :src="imageUrlArray[currentItemIndex]" />
      </div>
      <embed
        v-else-if="currentItemType === 'pdf'"
        class="pdf-wrapper"
        :src="`${imageUrlArray[currentItemIndex]}#view=Fit`"
      />
      <div
        v-else-if="currentItemType === 'other'"
      >Unable to show this file, URL: {{ imageUrlArray[currentItemIndex] }}</div>
      <a-button
        @click="gotoNext"
        :disabled="currentItemIndex >= imageUrlArray.length - 1"
        shape="circle"
        icon="right"
      />
    </div>
  </a-modal>
</template>

<script>
import PropTypes from "ant-design-vue/lib/_util/vue-types";
  

export default {
  name: "image-preview-modal",
  props: {
    closeAction: {
      type: Function,
      default: (succeed) => {},
    },
    imageUrls: { type: [String, Array] },
  },
  data() {
    return {
      imageUrlArray: [null], // 图片的数组
      currentItemIndex: 0, // 当前显示的文件在数组中的index
    };
  },
  created() {
    this.init();
  },
  updated() {
    this.init();
  },
  computed: {
    // 展示在标题里的分页信息
    indexText() {
      return `( ${this.currentItemIndex + 1} / ${this.imageUrlArray.length} )`;
    },
    // 当前展示文件的类型
    currentItemType() {
      const url = this.imageUrlArray[this.currentItemIndex]
        .toString()
        .toLowerCase();

      if (/\.(jpg|jpeg|png|gif)$/.test(url)) {
        return "img";
      } else if (/\.pdf$/.test(url)) {
        return "pdf";
      } else {
        return "other";
      }
    },
  },
  methods: {
    init() {
      if (!Array.isArray(this.imageUrls)) {
        this.imageUrlArray = [this.imageUrls];
      } else {
        this.imageUrlArray = this.imageUrls;
      }
    },
    gotoPrevious() {
      if (this.currentItemIndex > 0) this.currentItemIndex--;
    },
    gotoNext() {
      if (this.currentItemIndex < this.imageUrlArray.length - 1)
        this.currentItemIndex++;
    },
  },
};
</script>

<style scoped >
.wrapper {
  display: flex;
  align-items: center;
}
.image-wrapper {
  margin: 10px;
  height: 80vh;
  overflow-y: auto;
  flex: 1;
}
.pdf-wrapper {
  height: 80vh;
  padding: 10px;
  flex: 1 1 auto;
}
</style>
